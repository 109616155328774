<template>
  <div
    :class="[
      'flex flex-col min-h-screen',
      isHomePage ? 'bg-white' : 'bg-gray-100',
    ]"
  >
    <div v-if="bannerMessage" v-show="isBannerVisible" class="relative bg-cred">
      <div class="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p class="font-medium text-white">
            <span class="md:inline">
              {{ bannerMessage }}
            </span>
            <span v-if="bannerLink" class="block sm:ml-2 sm:inline-block">
              <a
                :href="bannerLink"
                target="_blank"
                class="font-bold text-white underline"
              >
                {{ $t('readMore') }} <span aria-hidden="true">&rarr;</span>
              </a>
            </span>
          </p>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 mt-1 md:mt-0 sm:pt-1 sm:pr-2 md:items-center"
        >
          <button
            type="button"
            class="flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
            @click="keepBannerClosed"
          >
            <span class="sr-only">Dismiss</span>
            <Icon
              name="heroicons:x-mark-solid"
              size="16"
              aria-hidden="true"
              class="text-white z-11"
            />
          </button>
        </div>
      </div>
    </div>

    <TheNavigation
      is-dark-mode
      :banner-message="bannerMessage"
      :banner-link="bannerLink"
      class="sticky top-0 z-10 flex-none"
    >
      <PublicNavigation @shopping-slider="handleShoppingCartSlideover" />
    </TheNavigation>
    <main class="relative flex-grow">
      <slot />
    </main>

    <Slideover
      :is-open="isShoppingCartSliderOpen"
      :title="selectedVehiclesStorage?.size ? $t('funnel.yourSelection') : ''"
      @close="handleCloseSlideover"
    >
      <FunnelShoppingCartVehicles
        :selected-vehicles-storage="selectedVehiclesStorage"
      />
    </Slideover>

    <ThePublicFooter class="flex-none" />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useCookie } from '#app'
import type { SelectedVehicleType } from '@/pages/request-quote/index.vue'

const config = useRuntimeConfig()

let bannerMessage,
  bannerLink = ''

if (config.public.IS_NAV_BANNER_ENABLED) {
  bannerMessage = config.public.NAV_BANNER_MESSAGE
  bannerLink = config.public.NAV_BANNER_LINK
}

const route = useRoute()
const isHomePage = computed(() => route.path === '/')
const isBannerVisible = ref(useCookie('bannerCookie').value === undefined)
const isShoppingCartSliderOpen = ref(false)
const { fetchStoredVehicles } = useVehicleRequestStorage()
const selectedVehiclesStorage = ref<Map<string, SelectedVehicleType>>(
  await fetchStoredVehicles()
)

function keepBannerClosed() {
  isBannerVisible.value = false

  const bannerCookie = useCookie('bannerCookie', {
    maxAge: 60 * 60 * 24 * 14,
    path: '/',
  })
  bannerCookie.value = JSON.stringify(isBannerVisible.value)
}

function handleShoppingCartSlideover() {
  isShoppingCartSliderOpen.value = true
}

function handleCloseSlideover() {
  isShoppingCartSliderOpen.value = false
}
</script>
